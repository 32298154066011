import React from 'react';
import EmojiItem from './EmojiItem';

interface EmojiDisplayProps {
  emojis: string[];
}

const EmojiDisplay: React.FC<EmojiDisplayProps> = ({ emojis }) => {
  return (
    <div className={`grid ${emojis.length === 1 ? 'grid-cols-1' : 'grid-cols-2 xs:grid-cols-3 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-6'} gap-4 mt-8`}>
      {emojis.map((emoji, index) => (
        <EmojiItem key={index} emoji={emoji} />
      ))}
    </div>
  );
};

export default EmojiDisplay;
