import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import axios from 'axios';

import enTranslations from './locales/en.json';
import zhTranslations from './locales/zh.json';

const resources = {
  en: { translation: enTranslations },
  zh: { translation: zhTranslations },
};

const getBrowserLanguage = () => {
  const language = navigator.language || (navigator as any).userLanguage;
  return language.toLowerCase().startsWith('zh') ? 'zh' : 'en';
};

const initI18n = () => {
  const browserLanguage = getBrowserLanguage();

  i18n
    .use(initReactI18next)
    .init({
      resources,
      lng: browserLanguage,
      fallbackLng: 'en',
      interpolation: {
        escapeValue: false,
      },
    });

  // 异步获取用户地理位置并更新语言（如果需要）
  axios.get('https://ipapi.co/json/')
    .then(response => {
      const countryCode = response.data.country_code.toLowerCase();
      const detectedLanguage = countryCode === 'cn' ? 'zh' : 'en';
      if (detectedLanguage !== browserLanguage) {
        i18n.changeLanguage(detectedLanguage);
      }
    })
    .catch(error => {
      console.error('Error detecting user language:', error);
    });
};

export default initI18n;