import React from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-hot-toast';

interface EmojiItemProps {
  emoji: string;
}

const EmojiItem: React.FC<EmojiItemProps> = ({ emoji }) => {
  const { t } = useTranslation();

  const copyToClipboard = () => {
    navigator.clipboard.writeText(emoji).then(() => {
      toast.success(t('copySuccess'), {
        duration: 2000,
        position: 'top-center',
      });
    }).catch(() => {
      toast.error(t('copyError'), {
        duration: 2000,
        position: 'top-center',
      });
    });
  };

  return (
    <div
      className="text-6xl cursor-pointer hover:bg-gray-200 p-4 rounded-lg transition-colors duration-200"
      onClick={copyToClipboard}
    >
      {emoji}
    </div>
  );
};

export default EmojiItem;