import React from 'react';
import { useTranslation } from 'react-i18next';

const Footer: React.FC = () => {
  const { t } = useTranslation();

  return (
    <footer className="mt-auto pt-4 text-white text-sm">
      <p>
        © {new Date().getFullYear()}{' '}
        <a
          href="https://sylwair.com"
          target="_blank"
          rel="noopener noreferrer"
          className="underline hover:text-blue-200"
        >
          Sylwair
        </a>
        . {t('footer.copyright')}
      </p>
      {/* <p>
        {t('footer.poweredBy')}{' '}
        <a
          href="https://github.com/your-github-repo"
          target="_blank"
          rel="noopener noreferrer"
          className="underline hover:text-blue-200"
        >
          Emoji Search
        </a>
      </p> */}
    </footer>
  );
};

export default Footer;