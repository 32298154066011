import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import SearchBox from './components/SearchBox';
import EmojiDisplay from './components/EmojiDisplay';
import { searchEmojis } from './api/emojiApi';
import { Toaster } from 'react-hot-toast';
import useScreenHeight from './hooks/useScreenHeight';
import Footer from './components/Footer';

const App: React.FC = () => {
  const { t } = useTranslation();
  const [emojis, setEmojis] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const isSmallScreen = useScreenHeight(500);

  const handleSearch = async (query: string) => {
    setLoading(true);
    setError(null);
    try {
      const result = await searchEmojis(query);
      setEmojis(result);
    } catch (error) {
      console.error('Error searching emojis:', error);
      setError(t('error'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={`h-screen bg-gradient-to-r from-purple-500 to-pink-500 flex flex-col items-center p-4 ${isSmallScreen ? 'pt-2' : 'pt-28'}`}>
      {!isSmallScreen && (
        <h1 className="text-4xl font-bold text-white mb-8">{t('title')}</h1>
      )}
      <div className="flex-grow overflow-auto w-full flex flex-col items-center">
        <SearchBox onSearch={handleSearch} />
        {loading ? (
          <p className="text-white mt-4">{t('loading')}</p>
        ) : (
          <EmojiDisplay emojis={emojis} />
        )}
        {error && <p className="text-red-500 mt-4">{error}</p>}
      </div>
      <Toaster />
      <Footer />
    </div>
  );
};

export default App;