import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface SearchBoxProps {
  onSearch: (query: string) => void;
}

const SearchBox: React.FC<SearchBoxProps> = ({ onSearch }) => {
  const { t } = useTranslation();
  const [query, setQuery] = useState('');

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSearch(query);
  };

  return (
    <form onSubmit={handleSubmit} className="w-full max-w-md">
      <div className="flex items-center bg-white rounded-full shadow-md overflow-hidden">
        <input
          className="appearance-none bg-transparent border-none flex-grow flex-shrink text-gray-700 py-3 px-2 leading-tight focus:outline-none"
          type="text"
          placeholder={t('searchPlaceholder')}
          value={query}
          onChange={(e) => setQuery(e.target.value)}
        />
        <button
          className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-3 px-3 rounded-r-full transition duration-300 ease-in-out flex-shrink-0 whitespace-nowrap xs:block"
          type="submit"
        >
          {t('searchButton')}
        </button>
      </div>
    </form>
  );
};

export default SearchBox;