import { useState, useEffect } from 'react';

const useScreenHeight = (threshold: number) => {
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const checkScreenHeight = () => {
      setIsSmallScreen(window.innerHeight < threshold);
    };

    checkScreenHeight();
    window.addEventListener('resize', checkScreenHeight);

    return () => {
      window.removeEventListener('resize', checkScreenHeight);
    };
  }, [threshold]);

  return isSmallScreen;
};

export default useScreenHeight;
